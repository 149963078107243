import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Login from '../views/Login';
import Register from '../views/Register';
import RecoverPassword from '../views/RecoverPassword';
import NotFound from '../views/404';

const redirects = ['/home', '/profile'];

const AuthStack = () => {
	return (
		<BrowserRouter>
			<Switch>
				<Route path={redirects}>
					<Redirect to='/' />
				</Route>
				<Route exact path='/'>
					<Login />
				</Route>
				<Route exact path='/register'>
					<Register />
				</Route>
				<Route exact path='/forgot-password'>
					<RecoverPassword />
				</Route>
				<Route path='*'>
					<NotFound />
				</Route>
			</Switch>
		</BrowserRouter>
	);
};

export default AuthStack;

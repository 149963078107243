import React from 'react';
import Loader from 'react-loader-spinner';

import { Container } from './styles';

const Fallback = () => (
	<Container>
		<Loader type='Bars' height={100} width={100} timeout={0} className='loader' />

		<span>Carregando</span>
	</Container>
);

export default Fallback;

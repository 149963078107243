import styled, { keyframes } from 'styled-components';

const FirstKeyframe = keyframes`
    0% {
        transform: rotateZ(0deg);
    }

    30% {
        transform: rotateZ(-25deg);
    }

    60% {
        transform: rotateZ(25deg);
    }

    100% {
        transform: rotateZ(0deg);
    }
`;

const LastKeyframe = keyframes`
    0% {
        transform: rotateZ(30deg);
    }

    10% {
        transform: rotateZ(15deg);
    }

    20% {
        transform: rotateZ(-35deg);
    }

    40% {
        transform: rotateZ(-25deg);
    }

    80% {
        transform: rotateZ(10deg);
    }

    90% {
        transform: rotateZ(30deg);
    }

    100% {
        transform: rotateZ(40deg);
    }
`;

const ZeroKeyframe = keyframes`
    0% {
        transform: translateY(0px);
    }

    30% {
        transform: translateY(15px);
    }

    70% {
        transform: translateY(-35px);
    }

    100% {
        transform: translateY(2px);
    }
`;

export const Container = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: var(--primary);

	& button.get-back {
		background: #fff;
		color: var(--heading-1);
	}
`;

export const Container404 = styled.div`
	display: flex;
	margin-bottom: 24px;

	color: var(--heading-1);
`;

export const Letter = styled.span`
	font-size: 220px;
	font-weight: 700;
	margin-right: 32px;
	font-family: 'Poppins', sans-serif;

	&:last-child {
		margin: none;
	}

	&:nth-child(1) {
		animation: ${FirstKeyframe};
		animation-duration: 5500ms;
		animation-iteration-count: infinite;
	}

	&:nth-child(2) {
		animation: ${ZeroKeyframe};
		animation-duration: 5500ms;
		animation-iteration-count: infinite;
	}

	&:nth-child(3) {
		animation: ${LastKeyframe};
		animation-duration: 5500ms;
		animation-iteration-count: infinite;
		/* transition-delay: 1200ms; */
		transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
	}
`;

export const Text = styled.p`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;
	color: var(--heading-1);
	margin-bottom: 48px;

	& > strong {
		font-size: 40px;
	}

	& > span {
		font-size: 22px;
	}
`;

import styled, { css } from 'styled-components';

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 24px;
	/* border: 1px solid blue; */
`;

export const Header = styled.header`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid var(--input-border-hover);
	border-radius: 3px 3px 0 0;
	padding: 4px 8px;
	background: #f5f5f5;

	& > div {
		display: flex;
		flex-direction: column;

		& > h3 {
			font-size: 16px;
			color: var(--heading-1);
			margin-bottom: 2px;
		}

		& > span {
			font-size: 14px;
			color: var(--body-1);
		}
	}

	& > button {
		background: none;
		font-size: 14px;
		font-weight: 600;
		padding: 4px;
		color: var(--primary);
		cursor: pointer;
		max-width: max-content;
		text-align: start;
		border-radius: var(--border-radius-1);
		transition: background-color 300ms;

		&:hover {
			background: #ffc2001c;
		}
	}
`;

export const Loading = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background: none;
	justify-content: center;
	border: none;
	width: 100%;

	& > p {
		font-size: 14px;
		font-weight: 600;
		margin-left: 12px;
		color: var(--primary);
	}
`;

export const Script = styled.ul`
	width: 100%;
	padding: 12px;
	border-radius: 0 0 3px 3px;
	border: 1px solid var(--input-border);
	border-top: none;

	${({ hightlight }) =>
		hightlight &&
		css`
			& > li {
				opacity: 0.4;
			}

			& > li.voice {
				opacity: 1;
			}
		`}
`;

export const Row = styled.li`
	width: 100%;
	color: ${({ color }) => color};
	background: ${({ color }) => color}35;
	border: 1px solid ${({ color }) => color};
	border-radius: var(--border-radius-1);
	font-size: 13px;
	font-weight: 600;
	text-transform: uppercase;
	padding: 12px 0;
	text-align: center;
	transition: 500ms;
	transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
	pointer-events: none;

	& ~ li {
		margin-top: 2px;
	}

	${({ isNewBlock }) =>
		isNewBlock &&
		css`
			border-color: var(--heading-1);
			color: var(--heading-1);
			background: #cacaca35;
		`}
`;

export const collapseTheme = {
	collapse: 'ReactCollapse--collapse',
	content: 'ReactCollapse--content',
};

import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import readXlsxFileBrowser from 'read-excel-file';
import * as Yup from 'yup';
import Recdal from 'recdal';
import { format, parseISO, addDays, getDay } from 'date-fns';

import { GET, POST } from '../../services/api';

import PlaylistVisualization from '../../components/PlaylistVisualization';
import VoicesUploader from '../../components/VoicesUploader';
import Drawer from '../../components/Drawer';
import Script from '../../components/Script';
import Breadcumbs from '../../components/Breadcumbs';
import Fallback from '../../components/Fallback';
import Button from '../../components/Button';
import { Form, Input, Select } from '../../components/Form';
import { Heading1, Heading2, Heading3 } from '../../components/Typography';
import Infos from './Infos';
import { notify } from '../../components/Notification';
import {
	Container,
	Grid,
	Column,
	SubContainer,
	ModalContent,
	DownloadPlaylistButton,
	SendButtonsContainer,
	OpenUploadButtonContainer,
	DrawerContent,
	GeneratedPlaylist,
	GenPlaylistItem,
} from './styles';

import { GoUpload } from 'react-icons/go';
import { BsDownload } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';

import { AuthContext } from '../../store/Auth';
import { resolveFileSrc } from '../../helpers/fileSrcResolver';

const breadcumbsStack = [
	{ label: 'Início', pathname: '/' },
	{ label: 'Enviar Offs', pathname: null },
];

const SendOffs = () => {
	const { user } = useContext(AuthContext);

	const modalRef = useRef(null);
	const uploaderModalRef = useRef(null);
	const playlistFormRef = useRef(null);
	const genPlaylistFormRef = useRef(null);

	const [contract, setContract] = useState(null);
	const [fallback, setFallback] = useState('initial-data');
	const [showDrawer, setShowDrawer] = useState(false);
	const [voices, setVoices] = useState([]);
	const [filesNeeded, setFilesNeeded] = useState(null);
	const [date, setDate] = useState('');
	const [script, setScript] = useState({});
	const [contracts, setContracts] = useState([]);
	const [playlistLink, setPlaylistLink] = useState('');
	const [playlistRows, setPlaylistRows] = useState([]);
	const [fetchingPlaylist, setFetchingPlaylist] = useState(false);
	const [fetchingScript, setFetchingScript] = useState('unmounted');
	const [dailyData, setDailyData] = useState(null);

	const [genType, setGenType] = useState(null);
	const [generatedPlaylist, setGeneratedPlaylist] = useState([]);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	const [verifyingPlaylists, setVerifyingPlaylists] = useState(false);
	const [lockPlaylistsGeneration, setLockPlaylistsGeneration] = useState(false);

	const handleSelectContract = useCallback(async ({ value }) => {
		try {
			setContract(value);
			setFetchingScript('loading');

			const {
				data: { script },
			} = await GET(`api/v2/scripts/${value.script._id}`);

			setScript(script);
			setFetchingScript('loaded');
		} catch (error) {
			console.error(error);
		}
	}, []);

	const handleFetchDayPlaylist = useCallback(
		async (formData) => {
			try {
				const validationSchema = Yup.object().shape({
					date: Yup.string().required('Informe o dia'),
				});

				await validationSchema.validate(formData);

				setPlaylistLink('');
				setPlaylistRows([]);
				setFetchingPlaylist(true);
				playlistFormRef.current.setFieldError('date', '');
				modalRef.current.lock();

				const date = format(parseISO(formData.date), 'dd/MM/yyyy');
				const url = `/api/v2/playlists?program=${contract?.program?._id}&date=${date}`;
				const {
					data: { playlists },
				} = await GET(url);

				if (!playlists.length) {
					modalRef.current.unlock();
					setFetchingPlaylist(false);
					return notify(
						'warn',
						'Não há playlists cadastradas para o programa selecionado nesse dia'
					);
				}

				const playlist = playlists[0];
				const playlistLink = resolveFileSrc({
					fileName: playlist.filename,
					customS3Bucket: process.env.REACT_APP_PLAYLISTS_BUCKET,
				});

				let blob = await fetch(playlistLink);

				blob = await blob.blob();
				const rows = await readXlsxFileBrowser(blob);

				modalRef.current.unlock();
				setFetchingPlaylist(false);
				setPlaylistRows(rows);
				setPlaylistLink(playlistLink);
			} catch (error) {
				if (error instanceof Yup.ValidationError) {
					return playlistFormRef.current.setFieldError('date', error.message);
				}
			}
		},
		[contract]
	);

	const handleCloseModal = () => {
		setPlaylistLink('');
		setPlaylistRows([]);
		modalRef.current.close();
	};

	const reset = (storedOffs) => {
		setVoices([]);
		setDailyData((current) => ({ ...current, storedOffs }));
	};

	const handleGeneratePlaylist = useCallback(
		async (formData) => {
			try {
				if (lockPlaylistsGeneration) {
					return notify('error', 'As playlists dessa semana já foram geradas');
				}

				if (!formData.start) {
					return genPlaylistFormRef.current.setFieldError('start', 'Informe a data de início');
				}

				if (!genType) {
					return genPlaylistFormRef.current.setFieldError('type', 'Informe o tipo');
				}

				const start = format(parseISO(formData.start), 'yyyy-MM-dd');
				const end = format(parseISO(endDate), 'yyyy-MM-dd');
				const payload = {
					programId: contract.program._id,
					week: [start, end],
					type: genType,
				};

				genPlaylistFormRef.current.setErrors({});
				setFallback('gen-playlist');

				const { data } = await POST('/api/v2/playlists/generate-playlist', payload);

				setGeneratedPlaylist(data);
				setFallback(null);

				return notify('success', 'Playlists geradas com sucesso');
			} catch (error) {
				console.error(error);
				setShowDrawer(false);
				setFallback(null);

				if (error?.response) {
					const { message } = error.response.data;
					return notify('error', message);
				}

				return notify('error', 'Houve um erro, tente novamente');
			}
		},
		[endDate, genType, contract, lockPlaylistsGeneration]
	);

	const handleChangeStartDate = useCallback(({ target: { value: date } }) => {
		date = parseISO(date);
		const day = getDay(date);

		if (isNaN(day)) return;
		if (day !== 1) {
			genPlaylistFormRef.current.setFieldValue('start', '');

			return notify('error', 'O início precisa ser uma Segunda-Feira');
		}

		return setStartDate(format(date, 'yyyy-MM-dd'));
	}, []);

	useEffect(() => {
		try {
			const fetchData = async () => {
				let { data } = await GET(`/api/v2/contracts?caster=${user._id}`);

				const contracts = data.contracts
					.filter(({ status, program }) => status === 1 && program !== null)
					.map((item) => ({
						label: (
							<>
								<strong>{item?.hirer?.radioName}</strong>
								<span> - {item?.program?.name}</span>
							</>
						),
						value: {
							_id: item?._id,
							radio: item?.hirer,
							script: item?.script,
							program: item?.program,
							playlistPrivilege: item?.playlistPrivilege,
						},
					}));

				setContracts(contracts);
				setFallback(null);
			};

			fetchData();
		} catch (error) {
			console.error(error);
		}
	}, [user]);

	useEffect(() => {
		setVoices([]);
		setFilesNeeded(null);

		if (script.body) {
			let scriptVoices = 0;

			script.body.forEach((element) => {
				if (element.type === 'OFF-MAIN') {
					scriptVoices++;
				}
			});

			return setFilesNeeded(scriptVoices);
		}
	}, [script]);

	useEffect(() => {
		const fetchDailyData = async () => {
			try {
				setDailyData(null);
				setFallback('daily-data');

				const contractId = contract?._id;
				const programId = contract?.program?._id;
				const dateRelease = format(parseISO(date), 'dd/MM/yyyy');

				const [{ data: observations }, { data: storedOffs }] = await Promise.all([
					GET(`/api/v2/observations?date=${dateRelease}&contract=${contractId}`),
					GET(`/api/v2/offs?program=${programId}&dateRelease=${dateRelease}`),
				]);

				setFallback(null);
				setDailyData({
					observations: observations.observations,
					storedOffs: storedOffs.offs.length,
				});
			} catch (error) {
				console.error(error);
			}
		};

		if (date && contract) {
			fetchDailyData();
		}
	}, [date, contract]);

	useEffect(() => {
		setGeneratedPlaylist([]);
		setStartDate(null);
		setEndDate(null);
	}, [contract]);

	const countPlaylistScriptLiveDays = useCallback((script) => {
		let count = 0;

		Object.values(script.week).forEach((day) => {
			if (day?.length) {
				count = count + 1;
			}
		});

		return count;
	}, []);

	useEffect(() => {
		const verifyPlaylistsExistance = async () => {
			try {
				if (startDate) {
					genPlaylistFormRef.current.setErrors({});

					const TALK_ID = '5e95ebab3542581e548c6e13';

					if (contract?.radio?._id === TALK_ID) {
						let query = `page=0&limit=7&program=${contract?.program?._id}`;

						setVerifyingPlaylists(true);

						for (let index = 0; index <= 7; index++) {
							query = `${query}&date=${format(addDays(parseISO(startDate), index), 'dd/MM/yyyy')}`;
						}

						const {
							data: { playlistScript },
						} = await GET(`/api/v2/playlist-scripts/${contract?.program?._id}`);

						const {
							data: { total },
						} = await GET(`/api/v2/playlists?${query}`);

						setVerifyingPlaylists(false);

						if (total >= countPlaylistScriptLiveDays(playlistScript)) {
							setLockPlaylistsGeneration(true);
							notify('warn', 'As playlists dessa semana já foram geradas');
						} else {
							setLockPlaylistsGeneration(false);
						}
					}

					let endDate = addDays(parseISO(startDate), 6);
					endDate = format(endDate, 'yyyy-MM-dd');

					return setEndDate(endDate);
				}
			} catch (error) {
				console.error(error);
			}
		};

		verifyPlaylistsExistance();
	}, [startDate, contract, countPlaylistScriptLiveDays]);

	if (fallback === 'initial-data') {
		return <Fallback />;
	}

	return (
		<>
			<Container>
				<Breadcumbs stack={breadcumbsStack} />
				<Heading1>Enviar Offs</Heading1>

				<Grid>
					<Column>
						<SubContainer>
							<Form>
								<Select
									size={4}
									name='contract'
									label='Selecione o contrato'
									options={contracts}
									onChange={handleSelectContract}
								/>

								<Input
									size={4}
									name='dateRelease'
									label='Selecione a data'
									disabled={!contract}
									type='date'
									onChange={({ target: { value } }) => setDate(value)}
								/>
							</Form>
							{fetchingScript !== 'unmounted' && (
								<Script script={script} loading={!!(fetchingScript === 'loading')} />
							)}
						</SubContainer>
					</Column>

					<Column>
						<SubContainer style={{ minHeight: 162 }}>
							<SendButtonsContainer>
								<OpenUploadButtonContainer>
									<Button
										onClick={() => uploaderModalRef.current.open()}
										disabled={!dailyData || dailyData.storedOffs === filesNeeded}
									>
										Selecionar Vozes
									</Button>
								</OpenUploadButtonContainer>

								<div className='playlist-buttons'>
									<Button
										onClick={() => setShowDrawer(true)}
										disabled={!contract?.playlistPrivilege}
										variant='outlined'
									>
										Gerar Playlist
									</Button>
									<Button
										onClick={() => modalRef.current.open()}
										disabled={!contract}
										variant='outlined'
									>
										Ver Playlist
									</Button>
								</div>
							</SendButtonsContainer>
						</SubContainer>

						<SubContainer>
							<Infos
								contract={contract}
								dailyData={dailyData}
								fallback={fallback}
								offsNeeded={filesNeeded}
							/>
						</SubContainer>
					</Column>
				</Grid>
			</Container>

			<Recdal
				ref={uploaderModalRef}
				closeOnOverlayClick={false}
				overlay={{
					style: {
						background: 'rgba(0, 0, 0, .4)',
					},
				}}
			>
				<VoicesUploader
					modalRef={uploaderModalRef}
					reset={reset}
					date={date}
					uploadsList={voices}
					uploadsNeeded={filesNeeded}
					contract={contract}
					pickFiles={(files) => setVoices(files)}
					program={contract?.program?.name}
					removeFile={(removedIndex) =>
						setVoices(voices.filter((_, index) => index !== removedIndex))
					}
				/>
			</Recdal>

			<Recdal
				ref={modalRef}
				awaitBeforeOpening={false}
				awaitBeforeClosing={false}
				overlay={{
					style: {
						background: 'rgba(0, 0, 0, .4)',
					},
				}}
			>
				<ModalContent>
					<header>
						<Heading3>Buscar playlist</Heading3>
					</header>
					<Form ref={playlistFormRef} onSubmit={handleFetchDayPlaylist}>
						<Input size={4} name='date' type='date' label='Selecione o dia' />

						{playlistLink && (
							<DownloadPlaylistButton href={playlistLink}>
								<BsDownload /> <span>Baixar Playlist</span>
							</DownloadPlaylistButton>
						)}
					</Form>

					{playlistRows.length !== 0 && <PlaylistVisualization rows={playlistRows} />}

					<div className='buttons'>
						<Button
							color='#5f5f5f'
							variant='outlined'
							onClick={handleCloseModal}
							disabled={fetchingPlaylist}
						>
							Cancelar
						</Button>
						<Button
							color='success'
							onClick={() => playlistFormRef.current.submitForm()}
							loading={fetchingPlaylist}
						>
							Buscar Playlist
						</Button>
					</div>
				</ModalContent>
			</Recdal>

			<Drawer
				visible={showDrawer}
				onClose={() => {
					setShowDrawer(false);
					setGenType(null);
				}}
				lock={fallback === 'gen-playlist'}
			>
				<DrawerContent>
					<header>
						<Heading2>Gerar Playlist</Heading2>
						<button
							className={fallback === 'gen-playlist' ? 'disabled' : ''}
							onClick={() => {
								setShowDrawer(false);
								setGenType(null);
							}}
							disabled={fallback === 'gen-playlist'}
						>
							<MdClose />
						</button>
					</header>

					<p>
						Selecione somente a data de início e o sistema se encarregará de calcular toda a semana
						e gerar corretamente as playlists de cada dia para você{' '}
						<span role='img' aria-label=':)'>
							😃
						</span>
						.
					</p>

					<Form ref={genPlaylistFormRef} onSubmit={handleGeneratePlaylist}>
						<Input
							name='start'
							label='Data de início'
							type='date'
							size={4}
							onKeyDown={(e) => e.preventDefault()}
							onChange={handleChangeStartDate}
						/>

						<Select
							label='Tipo'
							name='type'
							size={4}
							options={[
								{ label: 'Padrão', value: 'full' },
								{ label: 'Programas lançamento', value: 'stepped' },
							]}
							onChange={({ value }) => setGenType(value)}
						/>

						{startDate && endDate && (
							<div className='dates-interval'>
								<strong>{format(parseISO(startDate), 'dd/MM/yyyy')}</strong>
								<span>até</span>
								<strong>{format(parseISO(endDate), 'dd/MM/yyyy')}</strong>
							</div>
						)}

						<Button
							variant='outlined'
							color='success'
							size={4}
							disabled={lockPlaylistsGeneration}
							loading={verifyingPlaylists || fallback === 'gen-playlist'}
						>
							Gerar Playlist
						</Button>
					</Form>

					{generatedPlaylist.length !== 0 && (
						<GeneratedPlaylist>
							<div className='title'>
								<span>Playlists Geradas</span>
								<div className='line'></div>
							</div>

							<ul>
								{generatedPlaylist.map((playlist, index) => {
									if (typeof playlist === 'string') {
										return (
											<GenPlaylistItem key={index} unactive>
												Programa não vai ao ar
											</GenPlaylistItem>
										);
									}

									if (playlist?.alreadyExists) {
										return (
											<GenPlaylistItem alreadyExists key={index}>
												A playlist do dia {playlist?.date} já havia sido gerada
											</GenPlaylistItem>
										);
									}

									return (
										<GenPlaylistItem key={index}>
											<a
												href={resolveFileSrc({
													customS3Bucket: process.env.REACT_APP_PLAYLISTS_BUCKET,
													fileName: playlist.filename,
												})}
												target='_blank'
												rel='noopener noreferrer'
												download
											>
												Playlist do dia {playlist?.date} <GoUpload />
											</a>
										</GenPlaylistItem>
									);
								})}
							</ul>
						</GeneratedPlaylist>
					)}
				</DrawerContent>
			</Drawer>
		</>
	);
};

export default SendOffs;

import styled, { css } from 'styled-components';

export const StyledTable = styled.table`
	width: 100%;
	border-radius: var(--border-radius-1);
	border-collapse: collapse;
	border-style: hidden;
	box-shadow: 0 0 0 1px #dedede;
`;

export const StyledTableHead = styled.thead`
	border-radius: var(--border-radius-1);

	@media (max-width: 960px) {
		display: none;
	}
`;

export const StyledTh = styled.th`
	padding: 8px 0 8px 12px;
	text-align: start;
	background: #ffffff;
	color: var(--heading-1);
	font-weight: 500;
	font-size: 13px;
	opacity: 0.9;

	border-top: 1px solid #dedede;
	border-bottom: 1px solid #dedede;

	text-transform: uppercase;

	&:first-child {
		border-radius: var(--border-radius-1);
	}

	&:last-child {
		border-radius: var(--border-radius-1);
	}
`;

export const StyledTableBody = styled.tbody`
	@media (max-width: 960px) {
		display: block;
		width: 100%;
		border-radius: 10px 10px 0 0;
	}
`;

export const StyledTr = styled.tr`
	&:nth-child(odd) {
		background: #f1f1f18f;
	}

	@media (max-width: 960px) {
		display: block;
		width: 100%;
		margin-bottom: 8px;
		border-radius: 10px;
		box-shadow: 0px 1px 4px rgba(23, 23, 37, 0.1);
	}
`;

export const StyledTd = styled.td`
	padding: 8px 0 8px 12px;
	border-bottom: 1px solid #dedede;
	color: var(--body-1);
	font-size: 15px;

	&:first-child {
		border-left: 1px solid #dedede;
	}

	&:last-child {
		border-right: 1px solid #dedede;
	}

	@media (max-width: 960px) {
		padding: 12px;
		display: flex;
		width: 100%;
		text-align: right;
		align-items: flex-end;
		justify-content: flex-end;
		position: relative;
		min-height: 55px;

		&::before {
			content: attr(label);
			position: absolute;
			top: 50%;
			left: 12px;
			transform: translateY(-50%);
			font-family: Roboto;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 26px;
			letter-spacing: 0.1px;
			color: #696974;
		}
	}

	@media (max-width: 350px) {
		height: 80px;
		min-height: 80px;
		max-height: 80px;
		margin-bottom: 4px;

		&::before {
			top: 12px;
			left: 8px;
			font-size: 12px;
		}
	}
`;

export const CheckboxContainer = styled.div`
	& > div {
		display: flex;
		align-items: center;

		& > input {
			width: 0;
			height: 0;
			visibility: hidden;
		}

		& > span.check {
			position: relative;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;

			& > svg {
				color: var(--body-2);
				font-size: 20px;
				z-index: 5;
			}

			& > svg.checked {
				color: var(--primary);
				font-size: 20px;
				z-index: 5;
			}

			&::after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 35px;
				height: 35px;
				transition: opacity 120ms;
				opacity: 0;
				border-radius: 100%;
				background: #7181fd23;
				z-index: 1;
			}

			&:hover {
				&::after {
					opacity: 1;
				}
			}
		}

		& > label {
			font-size: 15px;
			margin-left: 8px;
			color: var(--body-2);
			font-weight: 500;
		}
	}
`;

export const Switch = styled.div`
	width: 44px;
	height: 26px;
	border-width: 1px;
	border-style: solid;
	border-color: ${({ checked }) => (checked ? 'var(--primary)' : 'var(--input-border)')};
	border-radius: var(--border-radius-3);
	position: relative;
	cursor: pointer;
	background: ${({ checked }) => (checked ? 'var(--primary)' : 'var(--input-border)')};

	${({ checked }) =>
		checked &&
		css`
			box-shadow: inset 0 0 1px 1px rgba(40, 40, 40, 0.1);
		`}

	& > span {
		width: 20px;
		height: 20px;
		position: absolute;
		top: 2px;
		left: ${({ checked }) => (checked ? 'calc(100% - 22px)' : '2px')};
		background: var(--white);
		border-radius: 100%;
		transition: left 300ms;
		box-shadow: ${({ checked }) =>
			checked ? '0px 1px 1px 1px rgba(10, 10, 10, .2)' : '1px 1px 1px 1px rgba(5, 5, 5, .1)'};
	}
`;

import './collapse.css';

import React, { useRef, useState, useEffect } from 'react';
import { useField } from '@unform/core';
import InputMask from 'react-input-mask';
import { Collapse } from 'react-collapse';

import { InputContainer, Error, collapseTheme } from './styles';

const MaskedInput = ({
	size,
	help = '',
	mask,
	maskChar = '_',
	label,
	name,
	onChange,
	disabled,
	...rest
}) => {
	const maskedInputRef = useRef(null);
	const { fieldName, registerField, defaultValue = '', error = false } = useField(name);

	const [inputValue, setInputValue] = useState();

	useEffect(() => {
		registerField({
			name: fieldName,
			ref: maskedInputRef.current,
			path: 'value',
			setValue(ref, value) {
				ref.setInputValue(value);
			},
			clearValue(ref) {
				ref.setInputValue('');
			},
		});
	}, [fieldName, registerField]);

	useEffect(() => {
		setInputValue(defaultValue);
	}, [defaultValue]);

	return (
		<InputContainer size={size} error={error} disabled={disabled} className='__input'>
			{label && <label htmlFor={name}>{label}</label>}
			<InputMask
				ref={maskedInputRef}
				disabled={disabled}
				// defaultValue={inputValue}
				className='masked-input'
				value={inputValue}
				mask={mask}
				onChange={(e) => {
					setInputValue(e.target.value);

					if (typeof onChange === 'function') {
						onChange(e);
					}
				}}
				maskChar={maskChar}
				autoComplete='off'
				{...rest}
			/>

			<Collapse isOpened={!!error} theme={collapseTheme}>
				<Error>{error}</Error>
			</Collapse>
		</InputContainer>
	);
};

export default MaskedInput;

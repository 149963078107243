import React, { useContext } from 'react';

import { AuthContext } from '../store/Auth';

import AuthStack from './auth.routes';
import CasterStack from './caster.routes';

const Routes = () => {
	const { isLogged } = useContext(AuthContext);

	if (!isLogged) {
		return <AuthStack />;
	}

	return <CasterStack />;
};

export default Routes;

import React from 'react';
import { Tab, Tabs as ReactTabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { Container } from './styles';

const Tabs = ({ tabsList, content }) => {
	return (
		<Container>
			<ReactTabs>
				<TabList>
					{tabsList.map(({ label, ...rest }, index) => (
						<Tab key={index} {...rest}>
							{label}
						</Tab>
					))}
				</TabList>
				{content.map((item, index) => (
					<TabPanel key={index}>{item}</TabPanel>
				))}
			</ReactTabs>
		</Container>
	);
};

export default Tabs;

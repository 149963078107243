import styled, { css } from 'styled-components';

export const Container = styled.ul`
	width: 100%;
	border-radius: var(--border-radius-1);
`;

export const ListItem = styled.li`
	width: 100%;
	border: 1px solid var(--input-border);
	margin-bottom: 8px;
	border-radius: var(--border-radius-1);

	& > header {
		padding: 12px 16px;
		width: 100%;
		background: var(--white);
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: space-between;
		transition: background-color 150ms;
		border-radius: var(--border-radius-2);

		& > div.left {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			& > h3 {
				font-size: 16px;
				margin-bottom: 2px;
			}

			& > div.radio {
				display: flex;
				align-items: center;

				& > svg {
					margin-right: 4px;
					font-size: 20px;
					color: var(--primary);
				}

				& > p {
					font-size: 14px;
					color: var(--body-2);
				}
			}
		}

		& > div.right {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			& > * {
				margin-left: 48px;
			}

			& > p.date {
				font-size: 15px;
				color: var(--body-2);
			}

			& > svg {
				font-size: 18px;
				color: var(--primary);
				transition: transform 300ms;
				transform: ${({ collapsed }) => (collapsed ? 'rotate(180deg)' : 'rotate(0deg)')};
			}
		}

		&:hover {
			background: #f9f9f9;
		}

		@media (max-width: 911px) {
			flex-direction: column;

			& > div {
				width: 100%;
			}

			& > div.other {
				justify-content: space-between;

				& > * {
					margin-left: 0;
				}
			}
		}
	}

	${({ collapsed }) =>
		!collapsed &&
		css`
			& header {
				border-radius: var(--border-radius-1) var(--border-radius-1) 0 0;
			}
		`}
`;

export const Details = styled.div`
	background: var(--white);
	border-radius: 0 0 var(--border-radius-1) var(--border-radius-1);
	min-height: 200px;
	height: max-content !important;
	border: 1px solid transparent; // não mexer nisso, tá assombrado
	border-top: 1px solid #dedede;
`;

export const TabContent = styled.div`
	width: 100%;

	& > div.button-container {
		width: 100%;
		margin-bottom: 12px;
		display: flex;
		justify-content: flex-end;
	}
`;

export const TabHeader = styled.div`
	width: 100%;
	margin: 12px 0 6px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;

	& > span {
		font-size: 18px;
		color: var(--body-2);
		font-style: italic;
		font-weight: 500;
	}
`;

export const ContractInfos = styled.ul`
	width: 100%;

	& > li {
		border-bottom: 1px solid #e4e4e4;
		padding: 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		& > strong {
			color: var(--heading-1);
			font-size: 16px;
		}

		& > span {
			color: var(--body-1);
			font-size: 16px;

			& > button {
				color: var(--primary);
				font-size: 16px;
				background: none;
				cursor: pointer;
				padding: 4px 6px;
				border-radius: var(--border-radius-1);

				&:hover {
					color: var(--white);
					background: var(--primary);
				}
			}
		}

		&:last-child {
			border-bottom: none;
		}
	}
`;

export const Value = styled.p`
	font-size: 23px;
	color: var(--heading-1);
	font-weight: 600;

	& > span {
		font-size: 13px;
		color: var(--body-2);
		font-weight: 600;
	}
`;

export const Schedule = styled.div`
	width: 300px;

	& > strong {
		font-size: 14.33px;
		color: var(--body-1);
		margin-right: 8px;
		font-weight: 600;
	}
`;

export const Week = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	gap: 0 4px;
	padding: 8px 0;
`;

export const Day = styled.span`
	padding: 4px;
	border-radius: var(--border-radius-1);
	border-width: 1px;
	border-style: solid;
	text-transform: uppercase;
	font-weight: 600;
	grid-column: span 1;
	text-align: center;
	font-size: 12px;

	color: ${({ active }) => (active ? 'var(--white)' : 'var(--grey-1)')};
	border-color: ${({ active }) => (active ? 'var(--primary)' : 'var(--grey-1)')};
	background: ${({ active }) => (active ? 'var(--primary)' : 'var(--white))')};
	opacity: ${({ active }) => (active ? 1 : 0.7)};
	pointer-events: none;
`;

export const Header = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	margin: 6px 0;

	& > div.buttons {
		display: flex;
	}

	& > span {
		font-size: 12px;
		color: var(--body-2);
	}
`;

export const ActionButton = styled.div`
	font-size: 12px;
	color: var(--primary);
	border-radius: var(--border-radius-1);
	transition: background-color 200ms;
	padding: 4px 8px;
	font-weight: 600;
	cursor: pointer;

	&:hover {
		background: #f1f1f1;
	}

	& ~ button {
		margin-left: 2px;
	}

	${({ disabled }) =>
		disabled &&
		css`
			pointer-events: none;
			opacity: 0.6;
		`}
`;

export const Status = styled.span`
	font-size: 14px;
	position: relative;
	border-radius: 2px;
	padding-left: 8px;
	font-weight: 600;
	width: 80px;
	color: var(--body-2);

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: -10%;
		transform: translateY(-50%);
		width: 8px;
		height: 8px;
		border-radius: 100px;
		background: ${({ status }) => {
			switch (status) {
				case 1:
					return 'var(--success)';
				case 2:
					return 'var(--danger)';
				case 3:
					return 'rgba(0, 116, 232)';
				default:
					return 'rgba(0, 116, 232)';
			}
		}};
	}
`;

export const ModalContent = styled.div`
	width: 600px;
	border-radius: var(--border-radius-1);
	background: var(--white);
	box-shadow: var(--modal-box-shadow);

	& > header {
		width: 100%;
		padding: 12px 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid var(--input-border-hover);

		& > button {
			cursor: pointer;
			width: 30px;
			height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: background-color 300ms;
			border-radius: var(--border-radius-1);
			background: none;

			&:hover {
				background: #dedede;
			}

			& > svg {
				color: var(--body-1);
				font-size: 18px;
			}
		}

		& > h3 {
			margin: 0;
			font-size: 18px;
			color: var(--heading-1);
		}
	}

	& > div.body {
		padding: 16px;
		max-height: 85vh;
		overflow-y: auto;

		& > p {
			color: var(--body-2);
		}

		& > div {
			margin: 0;
		}

		& ul {
			border: none !important;
		}
	}

	& > div.buttons {
		border-top: 1px solid var(--input-border);
		grid-column: span 4;
		padding: 6px;
		margin-top: 4px;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		& > button ~ button {
			margin-left: 8px;
		}
	}
`;

export const Empty = styled.div`
	width: 100%;
	padding: 64px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	& > svg {
		font-size: 100px;
		margin-bottom: 12px;
		color: var(--grey-1);
	}

	& > p {
		color: var(--grey-1);
		font-size: 20px;
		font-weight: 600;
	}
`;

export const BilletActions = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-right: 12px;
`;

export const DateCell = styled.p`
	color: var(--body-1);

	& > span {
		color: var(--body-2);
		font-size: 14px;
		margin-left: 3px;
	}
`;

export const BilletStatus = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;

	& > div {
		font-size: 11px;
		color: var(--white);
		padding: 6px 12px;
		border-radius: 20px;
		font-weight: 600;
		display: flex;
		justify-content: center;
		text-transform: uppercase;

		color: ${({ status }) => {
			switch (status) {
				case 'waiting':
					return 'var(--primary)';
				case 'unpaid':
					return 'var(--danger)';
				case 'paid':
					return 'var(--success)';
				default:
					return 'black';
			}
		}};

		background: ${({ status }) => {
			switch (status) {
				case 'waiting':
					return '#d8b13535';
				case 'unpaid':
					return '#e74c3c30';
				case 'paid':
					return '#17ca1a35';
				default:
					return 'black';
			}
		}};
	}
`;

export const BilletsFallback = styled.div`
	width: 100%;
	height: 129px;
	display: flex;
	align-items: center;
	justify-content: center !important;

	& svg {
		color: var(--primary) !important;
	}

	& > span {
		font-size: 16px;
		font-weight: 600;
		margin-left: 16px;
		color: var(--primary);
	}
`;

export const BilletDetails = styled.div`
	width: 100%;
	border: 1px solid red;
	display: flex;
	flex-direction: column;
`;

export const BilletDetailsAction = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	margin-bottom: 40px !important;

	&:last-child {
		margin-bottom: 0 !important;

		& > div.line {
			display: none;
		}
	}

	& > div.line {
		width: 3px;
		height: 55px;
		position: absolute;
		top: 33px;
		left: 14px;
		background: #dedede;
		border-radius: 3px;
	}

	& > p {
		width: 100%;
		padding-left: 43px;
		font-size: 14px;
		color: var(--body-1);
		margin-top: -3px;

		& > span {
			font-size: 13px;
			color: var(--body-2);
		}
	}

	& > header {
		width: 100%;
		display: flex;
		align-items: center;
		margin-bottom: 6px;
	}
`;

export const TimelineIcon = styled.div`
	width: 30px;
	height: 30px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 12px;
	background: ${({ color }) => `var(--${color})`};

	& > svg {
		color: var(--white);
	}

	& > span {
		color: var(--heading-1);
		font-size: 15px;
	}
`;

export const GenBilletModalContent = styled.div`
	width: 100%;

	& > ul {
		width: 100%;
		margin-bottom: 12px;

		& > li {
			width: 100%;
			padding: 8px 4px;
			border-bottom: 1px dashed #dedede;
			display: flex;
			align-items: center;
			justify-content: space-between;

			& > span {
				font-size: 14px;
				color: var(--body-2);
			}

			& > p {
				font-size: 14px;
				color: var(--body-1);

				& > span {
					opacity: 0.5;
				}
			}

			& > p.value {
				font-size: 17px;
				font-weight: 600;
				color: var(--body-1);
			}
		}
	}
`;

export const GenerationLocked = styled.div`
	width: 100%;
	margin-bottom: 16px;
	padding: 6px;
	border: 1px solid var(--danger);
	border-radius: var(--border-radius-1);
	background: #ff2e2e1c;

	& > p {
		text-align: center;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 0.5px;
		color: var(--danger);
	}
`;

export const collapseTheme = {
	collapse: 'ReactCollapse--collapse',
	content: 'ReactCollapse--content',
};

export const collapseStyles = {
	borderRadius: '0 0 15px 15px',
};

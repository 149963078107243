import React, { useState, useRef, useEffect } from 'react';

import { UploadItem, Icon, UploadedFileIcon, Volume } from './styles';

import { MdCancel } from 'react-icons/md';
import { FiPlay, FiPause } from 'react-icons/fi';
import { FaMicrophoneAlt } from 'react-icons/fa';

import { limitStringSize } from '../../utils/helpers/strings';
import { resolveFileSrc } from '../../helpers/fileSrcResolver';

const UploadedFile = ({ index, fileSrc, removeFile = null }) => {
	const audioPlayerRef = useRef(null);

	const [src, setSrc] = useState(null);
	const [isPlaying, setIsPlaying] = useState(false);

	const toByteNotation = (value) => {
		const size = (value / 1000000).toFixed(1);

		if (size < 1) {
			return `~ ${(size * 100).toFixed(1)} kb`;
		}

		return `${size} mb`;
	};

	const handleToggleAudio = () => {
		const player = audioPlayerRef.current;
		return player.paused ? player.play() : player.pause();
	};

	useEffect(() => {
		if (fileSrc) {
			if (fileSrc?.fromServer) {
				return setSrc(resolveFileSrc({ fileName: fileSrc.filename }));
			} else {
				return setSrc(URL.createObjectURL(fileSrc));
			}
		}
	}, [fileSrc]);

	const handleVolumeChange = (e) => (audioPlayerRef.current.volume = e.target.value);

	return (
		<>
			<UploadItem>
				<div className='content'>
					<Icon>
						<FaMicrophoneAlt />
					</Icon>

					<div className='text'>
						<p>{limitStringSize(fileSrc.name, 25)}</p>
						{fileSrc?.size && <span>{toByteNotation(fileSrc.size)}</span>}
					</div>
				</div>

				<div className='actions'>
					<Volume
						isPlaying={isPlaying}
						type='range'
						min={0}
						max={1}
						step={0.1}
						onChange={handleVolumeChange}
					/>

					<UploadedFileIcon onClick={handleToggleAudio} isPlayer>
						{isPlaying ? <FiPause /> : <FiPlay />}
					</UploadedFileIcon>

					{removeFile && (
						<UploadedFileIcon onClick={() => removeFile(index)}>
							<MdCancel />
						</UploadedFileIcon>
					)}
				</div>
			</UploadItem>

			<audio
				ref={audioPlayerRef}
				src={src}
				onPause={() => setIsPlaying(false)}
				onEnded={() => setIsPlaying(false)}
				onPlay={() => setIsPlaying(true)}
			/>
		</>
	);
};

export default UploadedFile;

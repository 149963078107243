import React, { useCallback, useMemo } from 'react';
import {
	FiList,
	FiGlobe,
	FiVoicemail,
	FiMusic,
	FiStopCircle,
	FiMic,
	FiActivity,
	FiMinusSquare,
	FiDollarSign,
} from 'react-icons/fi';

const useElement = () => {
	const types = useMemo(() => {
		return [
			'NEW-BLOCK',
			'VIGNETTE-PROGRAM-INTRO',
			'VIGNETTE-BREAK',
			'VIGNETTE-PROGRAM-OUTRO',
			'VIGNETTE-BLOCK-INTRO',
			'VIGNETTE-BLOCK-OUTRO',
			'OFF-LOCAL-PROGRAM-INTRO',
			'OFF-LOCAL-PROGRAM-OUTRO',
			'OFF-LOCAL-BLOCK-INTRO',
			'OFF-LOCAL-BLOCK-OUTRO',
			'OFF-LOCAL-BLOCK-MID',
			'CUSTOM',
			'SPONSOR',
			'OFFERING',
			'TESTIMONIAL',
			'SIGNATURE-RADIO',
			'SIGNATURE-PROGRAM',
			'CASHTRACK',
			'CASHTRACK-CALL',
			'OFF-RECORDED-PROGRAM-INTRO',
			'OFF-RECORDED-PROGRAM-OUTRO',
			'OFF-RECORDED-BLOCK-INTRO',
			'OFF-RECORDED-BLOCK-OUTRO',
			'OFF-RECORDED-BLOCK-MID-1',
			'OFF-RECORDED-BLOCK-MID-2',
			'OFF-RECORDED-BLOCK-MID-3',
			'OFF-RECORDED-BLOCK-MID-4',
			'OFF-RECORDED-BLOCK-MID-5',
			'NEWS-MAIN',
			'NEWS-LOCAL',
		];
	}, []);

	const categorizedTypes = useMemo(() => {
		return {
			defaultElements: [
				'NEW-BLOCK',
				'VIGNETTE-PROGRAM-INTRO',
				'VIGNETTE-BREAK',
				'VIGNETTE-PROGRAM-OUTRO',
				'VIGNETTE-BLOCK-INTRO',
				'VIGNETTE-BLOCK-OUTRO',
				'OFF-MAIN',
				'TRACK',
			],
			localOffs: [
				'OFF-LOCAL-PROGRAM-INTRO',
				'OFF-LOCAL-PROGRAM-OUTRO',
				'OFF-LOCAL-BLOCK-INTRO',
				'OFF-LOCAL-BLOCK-OUTRO',
				'OFF-LOCAL-BLOCK-MID',
			],
			merchanElements: ['SPONSOR', 'OFFERING', 'TESTIMONIAL', 'CASHTRACK'],
			recordedOffs: [
				'OFF-RECORDED-PROGRAM-INTRO',
				'OFF-RECORDED-PROGRAM-OUTRO',
				'OFF-RECORDED-BLOCK-INTRO',
				'OFF-RECORDED-BLOCK-OUTRO',
				'OFF-RECORDED-BLOCK-MID-1',
				'OFF-RECORDED-BLOCK-MID-2',
				'OFF-RECORDED-BLOCK-MID-3',
				'OFF-RECORDED-BLOCK-MID-4',
				'OFF-RECORDED-BLOCK-MID-5',
			],
			signatures: ['SIGNATURE-RADIO', 'SIGNATURE-PROGRAM'],
			news: [
				'GENERIC',
				'INTERNATIONAL',
				'HEALTH',
				'MONEY',
				'EDUCATION',
				'POLICY',
				'ECONOMY',
				'ELECTIONS',
				'FARMING',
				'ENTERTAINMENT',
				'TECHNOLOGY',
				'CINE',
				'SPORTS',
			],
			localNews: [
				'NEWS-LOCAL-GENERIC',
				'NEWS-LOCAL-INTERNATIONAL',
				'NEWS-LOCAL-HEALTH',
				'NEWS-LOCAL-MONEY',
				'NEWS-LOCAL-EDUCATION',
				'NEWS-LOCAL-POLICY',
				'NEWS-LOCAL-ECONOMY',
				'NEWS-LOCAL-ELECTIONS',
				'NEWS-LOCAL-FARMING',
				'NEWS-LOCAL-ENTERTAINMENT',
				'NEWS-LOCAL-TECHNOLOGY',
				'NEWS-LOCAL-CINE',
				'NEWS-LOCAL-SPORTS',
			],
		};
	}, []);

	const parseElementName = useCallback(({ type }) => {
		return {
			/** Elementos padrão */
			'NEW-BLOCK': 'BLOCO',
			'VIGNETTE-PROGRAM-INTRO': 'VINHETA - INTRODUÇÃO DE PROGRAMA',
			'VIGNETTE-BREAK': 'VINHETA - PASSAGEM',
			'VIGNETTE-PROGRAM-OUTRO': 'VINHETA - ENCERRAMENTO DE PROGRAMA',
			'VIGNETTE-BLOCK-INTRO': 'VINHETA - INTRODUÇÃO DE BLOCO',
			'VIGNETTE-BLOCK-OUTRO': 'VINHETA - ENCERRAMENTO DE BLOCO',
			'OFF-MAIN': 'OFF REDE',
			TRACK: 'MÚSICA',
			/** Offs locais */
			'OFF-LOCAL-PROGRAM-INTRO': 'OFF LOCAL - INTRODUÇÃO DE PROGRAMA',
			'OFF-LOCAL-PROGRAM-OUTRO': 'OFF LOCAL - ENCERRAMENTO DE PROGRAMA',
			'OFF-LOCAL-BLOCK-INTRO': 'OFF LOCAL - INTRODUÇÃO DE BLOCO',
			'OFF-LOCAL-BLOCK-OUTRO': 'OFF LOCAL - ENCERRAMENTO DE BLOCO',
			'OFF-LOCAL-BLOCK-MID': 'OFF LOCAL - MEIO DE BLOCO',
			/** Publicidades */
			CUSTOM: 'CUSTOMIZADO',
			SPONSOR: 'PATROCÍNIO',
			OFFERING: 'OFERECIMENTO',
			TESTIMONIAL: 'TESTEMUNHAL',
			/** Cashtracks */
			CASHTRACK: 'MÚSICA-CASH',
			'CASHTRACK-CALL': 'CHAMADA PARA MÚSICA-CASH',
			/** Assinaturas */
			'SIGNATURE-RADIO': 'ASSINATURA - RÁDIO',
			'SIGNATURE-PROGRAM': 'ASSINATURA - PROGRAMA',
			/** Offs gravados */
			'OFF-RECORDED-PROGRAM-INTRO': 'OFF GRAVADO - INTRODUÇÃO DE PROGRAMA',
			'OFF-RECORDED-PROGRAM-OUTRO': 'OFF GRAVADO - ENCERRAMENTO DE PROGRAMA',
			'OFF-RECORDED-BLOCK-INTRO': 'OFF GRAVADO - INTRODUÇÃO DE BLOCO',
			'OFF-RECORDED-BLOCK-OUTRO': 'OFF GRAVADO - ENCERRAMENTO DE BLOCO',
			'OFF-RECORDED-BLOCK-MID-1': 'OFF GRAVADO - MEIO DE BLOCO - 1',
			'OFF-RECORDED-BLOCK-MID-2': 'OFF GRAVADO - MEIO DE BLOCO - 2',
			'OFF-RECORDED-BLOCK-MID-3': 'OFF GRAVADO - MEIO DE BLOCO - 3',
			'OFF-RECORDED-BLOCK-MID-4': 'OFF GRAVADO - MEIO DE BLOCO - 4',
			'OFF-RECORDED-BLOCK-MID-5': 'OFF GRAVADO - MEIO DE BLOCO - 5',
			/** Notícias */
			GENERIC: 'GERAL',
			INTERNATIONAL: 'INTERNACIONAL',
			HEALTH: 'SAÚDE E BEM ESTAR',
			MONEY: 'DINHEIRO',
			EDUCATION: 'EDUCAÇÃO',
			POLICY: 'POLÍTICA',
			ECONOMY: 'ECONOMIA',
			ELECTIONS: 'ELEIÇÕES',
			FARMING: 'AGROPECUÁRIA',
			ENTERTAINMENT: 'ENTRETENIMENTO',
			TECHNOLOGY: 'TECNOLOGIA',
			CINE: 'CINEMA E PIPOCA',
			SPORTS: 'ESPORTES',
		}[type];
	}, []);

	const getElementColor = useCallback(({ type }) => {
		return {
			'NEW-BLOCK': '#FFFFFF',
			'VIGNETTE-PROGRAM-INTRO': '#D32029',
			'VIGNETTE-BREAK': '#177DDC',
			'VIGNETTE-PROGRAM-OUTRO': '#CB2B83',
			'VIGNETTE-BLOCK-INTRO': '#AA6215',
			'VIGNETTE-BLOCK-OUTRO': '#642AB5',
			TRACK: '#d8BD14',
			'OFF-MAIN': '#49AA19',
			'OFF-LOCAL-PROGRAM-INTRO': '#00d859',
			'OFF-LOCAL-PROGRAM-OUTRO': '#00bd4e',
			'OFF-LOCAL-BLOCK-INTRO': '#00a243',
			'OFF-LOCAL-BLOCK-OUTRO': '#008636',
			'OFF-LOCAL-BLOCK-MID': '#006d2c',
			TESTIMONIAL: '#FFC800',
			'SIGNATURE-RADIO': '#007369',
			'SIGNATURE-PROGRAM': '#00c9b7',
			CASHTRACK: '#9f7d10',
			'OFF-RECORDED-PROGRAM-INTRO': '#50dcae',
			'OFF-RECORDED-PROGRAM-OUTRO': '#18c29c',
			'OFF-RECORDED-BLOCK-INTRO': '#0b877d',
			'OFF-RECORDED-BLOCK-OUTRO': '#126872',
			'OFF-RECORDED-BLOCK-MID-1': '#1b5258',
			'OFF-RECORDED-BLOCK-MID-2': '#183c40',
			'OFF-RECORDED-BLOCK-MID-3': '#0e2e31',
			'OFF-RECORDED-BLOCK-MID-4': '#082629',
			'OFF-RECORDED-BLOCK-MID-5': '#022225',
			/** Notícias */
			'NEWS-MAIN': '#022225',
			'NEWS-LOCAL': '#4d006d',
		}[type];
	}, []);

	const getElementIcon = useCallback(
		({ type }) => {
			if (!type) {
				return <FiList />;
			}

			if (type === 'NEW-BLOCK') return <FiMinusSquare />;
			if (type.startsWith('OFF-')) return <FiMic />;
			if (type === 'CUSTOM') return <FiStopCircle />;
			if (type.includes('TRACK')) return <FiMusic />;
			if (type.startsWith('VIGNETTE')) return <FiActivity />;
			if (type.startsWith('SIGNATURE')) return <FiActivity />;
			if (type.startsWith('NEWS-')) return <FiGlobe />;
			if (categorizedTypes.merchanElements.includes(type)) return <FiDollarSign />;
			if (categorizedTypes.recordedOffs.includes(type)) return <FiVoicemail />;

			return <FiList />;
		},
		[categorizedTypes]
	);

	return { types, categorizedTypes, parseElementName, getElementColor, getElementIcon };
};

export default useElement;

import React from 'react';

import {
	StyledTable,
	StyledTableBody,
	StyledTableHead,
	StyledTh,
	StyledTr,
	StyledTd,
} from './styles';

const Container = ({ children, ...rest }) => <StyledTable {...rest}>{children}</StyledTable>;

const Header = ({ children, ...rest }) => <StyledTableHead {...rest}>{children}</StyledTableHead>;

const HeaderCell = ({ children, ...rest }) => <StyledTh {...rest}>{children}</StyledTh>;

const Body = ({ children, ...rest }) => <StyledTableBody {...rest}>{children}</StyledTableBody>;

const Row = ({ children, ...rest }) => <StyledTr {...rest}>{children}</StyledTr>;

const Cell = ({ children, label, ...rest }) => (
	<StyledTd label={label} {...rest}>
		{children}
	</StyledTd>
);

export { Container, Header, HeaderCell, Body, Row, Cell };

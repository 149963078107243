import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    :root {
        --primary: #ffb200;
        --primary-hover: #e8a200;
        --primary-opacity: #ffb20015;

        --success: #32be5b;
        --success-hover: #24ae4c;
        --success-opacity: #24ae4c15;

        --danger: #ff3636;
        --danger-hover: #f12d2d;
        --danger-opacity: #ff363615;
        
        --dark: rgba(29, 32, 43);
        --white: #FFFFFF;
        --grey-1: #d6d6ca;

        --border-radius-1: 15px;
        --border-radius-2: 20px;
        --border-radius-3: 30px;

        --heading-1: #313648;
        --body-1: #47484c;
        --body-2: rgba(120, 130, 140);

        --menu-color: rgba(211, 214, 222);
        --menu-hover: rgba(48, 56, 67);

        --input-focus: #ecece8;
        --input-border: #dedede;
        --input-border-hover: #cccccc;

        --card-border: #efefef;
        --workspace: rgba(245, 249, 250);
        --modal-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: none;
        font-family: 'Poppins', sans-serif;
        list-style: none;
    }

    body, html, #root {
        height: 100%;
        overflow: hidden;
    }

    button {
        border: none;
    }
    
    a {
        text-decoration: none;
        color: inherit;
    }

    .sb-avatar__image {
        object-fit: cover !important;
    }
`;
export default GlobalStyles;

import React from 'react';
import GlobalStyles from '../styles/global';
import Routes from '../routes';
import { CombineProviders } from '../store';

import Notification from '../components/Notification';

const App = () => {
	return (
		<CombineProviders>
			<GlobalStyles />
			<Routes />

			<Notification />
		</CombineProviders>
	);
};

export default App;

import './collapse.css';

import React, { useEffect, useState, useRef } from 'react';
import ReactCurrencyInput from 'react-currency-input';
import { useField } from '@unform/core';
import { Collapse } from 'react-collapse';

import { InputContainer, Error, collapseTheme } from './styles';

const CurrencyInput = ({ size, help = '', label, name, onInputChange, ...rest }) => {
	const currencyInputRef = useRef(null);
	const { fieldName, registerField, defaultValue = '', error = false } = useField(name);

	const [value, setValue] = useState(() => {
		return defaultValue ? defaultValue : 0;
	});

	useEffect(() => {
		registerField({
			name: fieldName,
			ref: currencyInputRef.current,
			path: 'state.value',
		});
	}, [fieldName, registerField]);

	return (
		<InputContainer {...rest} size={size} error={error} className='__input'>
			{label && <label htmlFor={name}>{label}</label>}

			<ReactCurrencyInput
				{...rest}
				ref={currencyInputRef}
				value={value}
				className='masked-input'
				prefix='R$ '
				decimalSeparator=','
				thousandSeparator='.'
				placeholder={''}
				onChange={(e) => {
					if (typeof onInputChange === 'function') {
						onInputChange(e);
					}

					setValue(e);
				}}
			/>

			<Collapse isOpened={!!error} theme={collapseTheme}>
				<Error>{error}</Error>
			</Collapse>
		</InputContainer>
	);
};

export default CurrencyInput;

import React from 'react';

import { Container, Progress as Progressbar, ProgressIcon } from './styles';

import { BsCloudUpload } from 'react-icons/bs';
import { FiCheckCircle } from 'react-icons/fi';

const Progress = ({ progress, label }) => {
	return (
		<Container progress={progress}>
			<ProgressIcon progress={progress}>
				{progress === 100 ? <FiCheckCircle /> : <BsCloudUpload />}
			</ProgressIcon>

			<Progressbar progress={progress}>
				<p>
					{progress === 100 ? (
						<strong>Upload concluído</strong>
					) : (
						<>
							<strong>{progress}%</strong> <span>{label}</span>
						</>
					)}
				</p>
				<div className='progress-bar'>
					<div className='progress'></div>
				</div>
			</Progressbar>
		</Container>
	);
};

export default Progress;

import styled from 'styled-components';

export const Container = styled.div`
	background: ${({ progress }) => (progress === 100 ? 'var(--success)' : 'var(--primary)')};
	border-radius: var(--border-radius-1);
	width: 100%;
	display: flex;
	padding: 24px 48px;
`;

export const ProgressIcon = styled.div`
	width: 55px;
	height: 55px;
	border-radius: 100%;
	background: var(--white);
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	& > svg {
		font-size: 35px;
		color: ${({ progress }) => (progress === 100 ? 'var(--success)' : 'var(--primary)')};
	}

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		border-radius: 100%;
		transform: translate(-50%, -50%);
		width: 120%;
		height: 120%;
		box-shadow: 0px 0px 2px 0px var(--white);
	}
`;

export const Progress = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 16px;
	flex: 1;

	& > p {
		strong {
			font-size: 22px;
			font-weight: 400;
			color: var(--white);
		}

		span {
			font-size: 13px;
			font-weight: 300;
			color: var(--white);
		}
	}

	& > div.progress-bar {
		width: 100%;
		height: 15px;
		position: relative;
		border-radius: var(--border-radius-2);
		background: #ffd53c;

		& > div.progress {
			position: absolute;
			top: 0;
			left: 0;
			border-radius: var(--border-radius-3);
			height: 100%;
			width: ${({ progress }) => progress}%;
			background: ${({ progress }) => (progress === 100 ? '#27b029' : '#de9b00')};
			display: flex;
			align-items: center;
			justify-content: center;

			& > span {
				font-size: 10px;
				font-weight: 500;
				color: var(--white);
			}
		}
	}
`;

import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import Button from '../../components/Button';
import { Form, Input } from '../../components/Form';
import { Container, FormContainer, Header, Background, Overlay } from './styles';

import Bg1 from '../../assets/login/bg1.jpg';
import Bg2 from '../../assets/login/bg2.jpg';
import Bg3 from '../../assets/login/bg3.jpg';
import Bg4 from '../../assets/login/bg4.jpg';
import Bg5 from '../../assets/login/bg5.jpg';

const RecoverPassword = () => {
	const formRef = useRef(null);
	const [loading, setLoading] = useState(false);

	const getRandomLoginBackground = () => {
		const backgrounds = [Bg1, Bg2, Bg3, Bg4, Bg5];
		const random = Math.floor(Math.random() * backgrounds.length) + 1;

		return backgrounds[random - 1];
	};

	const handleSignIn = async (formData) => {
		try {
			const validationSchema = Yup.object().shape({
				email: Yup.string().required('Informe o seu email'),
			});

			await validationSchema.validate(formData);

			setLoading(true);
		} catch (error) {
			if (error instanceof Yup.ValidationError) {
				formRef.current.setFieldError('email', error.message);
			}
		}
	};

	return (
		<Container>
			<Header>
				<h1>Logo da Talk - Módulo Locutor</h1>
			</Header>
			<FormContainer>
				<h1>Recuperar Senha</h1>
				<Form ref={formRef} onSubmit={handleSignIn}>
					<Input size={4} name='email' type='email' label='Email' />
					<Button loading={loading} size={4}>
						Enviar email
					</Button>
				</Form>
				<p>
					<Link to={{ pathname: '/', state: { from: '/register' } }}>Voltar</Link>
				</p>
			</FormContainer>
			<Background bg={getRandomLoginBackground()}>
				<Overlay />
			</Background>
		</Container>
	);
};

export default RecoverPassword;

import React, { useState, useRef, useMemo, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { POST } from '../../services/api';
import { ZIPCODE } from '../../services/zipCode';

import Button from '../../components/Button';
import { Form, Input, FileInput, MaskedInput, Select, TextArea } from '../../components/Form';
import { Container, FormContainer, Background, Overlay } from './styles';
import { notify } from '../../components/Notification';

import Bg1 from '../../assets/login/bg1.jpg';
import Bg2 from '../../assets/login/bg2.jpg';
import Bg3 from '../../assets/login/bg3.jpg';
import Bg4 from '../../assets/login/bg4.jpg';
import Bg5 from '../../assets/login/bg5.jpg';

import { brazilianStates } from '../../constants';

const Register = () => {
	const history = useHistory();

	const formRef = useRef(null);
	const [state, setState] = useState('');
	const [fallback, setFallback] = useState(false);
	const [formData, setFormData] = useState({});

	const getRandomLoginBackground = useCallback(() => {
		const backgrounds = [Bg1, Bg2, Bg3, Bg4, Bg5];
		const random = Math.floor(Math.random() * backgrounds.length) + 1;

		return backgrounds[random - 1];
	}, []);

	const memoizedBackground = useMemo(() => getRandomLoginBackground(), [getRandomLoginBackground]);

	const handleChangeZipCode = async (e) => {
		try {
			const zipCode = e.target.value.replace(/\D+/g, '');

			if (zipCode.length === 7) {
				setState('');
				setFormData({
					...formRef.current.getData(),
					state: '',
					city: '',
				});
			}

			if (zipCode.length === 8) {
				const { data } = await ZIPCODE(zipCode);
				const { erro } = data;

				if (erro) {
					throw { code: 404, message: 'CEP não encontrado' }; // eslint-disable-line
				}

				formRef.current.setFieldError('zip', '');

				setState(data.uf);
				setFormData({
					...formRef.current.getData(),
					state: data.uf,
					city: data.localidade,
				});
			}
		} catch (error) {
			console.error(error);

			if (error.code === 404) {
				notify('warn', 'CEP não encontrado');
				return formRef.current.setFieldError('zip', error.message);
			}
		}
	};

	const handleSignUp = async (formData) => {
		try {
			formData.state = state;
			formData.zipCode = formData.zipCode.replace(/\D+/g, '');

			const validationSchema = Yup.object().shape({
				name: Yup.string().required('Informe seu primeiro nome'),
				surname: Yup.string().required('Informe seu sobrenome'),
				email: Yup.string().required('Informe seu e-mail'),
				password: Yup.string().required('Informe sua senha'),
				passwordConfirmation: Yup.string().oneOf(
					[Yup.ref('password'), null],
					'Senhas não são iguais'
				),
				zipCode: Yup.string().required('Informe seu CEP'),
				city: Yup.string().required('Informe sua cidade'),
				state: Yup.string().required('Informe seu estado'),
				mic: Yup.string().required('Informe seu microfone'),
				phone: Yup.string().required('Informe seu WhatsApp'),
				bio: Yup.string().required('Fale um pouco sobre você'),
				soundcard: Yup.string().required('Informe sua placa de som'),
				audioDemo: Yup.mixed().required('É necessário uma demo da sua voz'),
			});

			await validationSchema.validate(formData, { abortEarly: false });
			setFallback(true);

			const payload = new FormData();

			for (const key in formData) {
				if (formData.hasOwnProperty(key)) {
					payload.append([key], formData[key]);
				}
			}

			await POST('/api/v2/casters', payload);

			notify('success', 'Cadastro feito com sucesso, aguardando aprovação!', {
				onClose: () => history.push('/'),
			});
		} catch (error) {
			setFallback(false);

			if (error instanceof Yup.ValidationError) {
				const errors = {};
				error.inner.forEach(({ path, message }) => (errors[path] = message));

				return formRef.current.setErrors(errors);
			}

			if (error?.response?.data?.status === 409) {
				console.error(error?.response);

				return notify('error', 'Email já cadastrado');
			}

			notify('error', 'Houve um erro, tente novamente');
		}
	};

	return (
		<Container>
			<FormContainer>
				<h1>Criar Conta</h1>

				<p>
					Já possui uma conta?{' '}
					<Link to={{ pathname: '/', state: { from: '/register' } }}>Faça login</Link>
				</p>

				<Form ref={formRef} onSubmit={handleSignUp} initialData={formData}>
					<Input size={2} name='name' label='Nome' />
					<Input size={2} name='surname' label='Sobrenome' />
					<Input size={2} name='password' type='password' label='Senha' />
					<Input size={2} name='passwordConfirm' type='password' label='Confirmar Senha' />
					<Input size={2} name='email' label='Email' />
					<MaskedInput size={2} mask='(99) 9 9999-9999' name='phone' label='WhatsApp' />
					<MaskedInput
						size={2}
						mask='99999-999'
						name='zipCode'
						label='CEP'
						onChange={handleChangeZipCode}
					/>
					<Input size={2} name='city' label='Cidade' />
					<Select
						size={2}
						name='state'
						label='Estado'
						onChange={({ value }) => setState(value)}
						options={brazilianStates}
					/>
					<Input size={2} name='mic' label='Modelo do Seu Microfone' />
					<Input size={4} name='soundcard' label='Modelo da Sua Placa de Som' />

					<TextArea size={4} name='bio' label='Fale Um Pouco Sobre Você' rows={3} />

					<FileInput accept={['.mp3']} size={2} name='audioDemo' label='Demonstração da Voz' />
					<FileInput
						accept={['.jpeg', '.jpg', '.png', '.gif']}
						size={2}
						name='profilePic'
						label='Imagem de Perfil'
					/>

					<Button style={{ marginTop: '24px' }} loading={fallback} size={4}>
						Registrar-se
					</Button>
				</Form>
			</FormContainer>
			<Background bg={memoizedBackground}>
				<Overlay />
			</Background>
		</Container>
	);
};

export default Register;

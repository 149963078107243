import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Ancor, Chevron } from './styles';

const Breadcumbs = ({ stack }) => {
	const { pathname: currentLocation } = useLocation();

	if (!stack.length) {
		return null;
	}

	return (
		<Container>
			{stack.map(
				(link, index) =>
					index !== stack.length - 1 && (
						<Ancor key={index}>
							<Link to={{ pathname: link.pathname, state: { from: currentLocation } }}>
								{link.label}
							</Link>
							<Chevron />
						</Ancor>
					)
			)}
			<Ancor isLast>{stack[stack.length - 1].label}</Ancor>
		</Container>
	);
};

export default Breadcumbs;

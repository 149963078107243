import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Home from '../views/Home';
import SendOffs from '../views/SendOffs';
import Contracts from '../views/Contracts';
import Profile from '../views/Profile';
import NotFound from '../views/404';

import Layout from '../components/Layout';

const redirects = ['/', '/register', '/forgot-password'];

const CasterStack = () => {
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path={redirects}>
					<Redirect to='/home' />
				</Route>
				<Route exact path='/home'>
					<Layout>
						<Home />
					</Layout>
				</Route>
				<Route exact path='/send-offs'>
					<Layout>
						<SendOffs />
					</Layout>
				</Route>
				<Route exact path='/contracts'>
					<Layout>
						<Contracts />
					</Layout>
				</Route>
				<Route exact path='/profile'>
					<Layout>
						<Profile />
					</Layout>
				</Route>
				<Route exact path='/*'>
					<NotFound />
				</Route>
			</Switch>
		</BrowserRouter>
	);
};

export default CasterStack;

import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	height: 100%;

	& > h1 {
		color: var(--body-1);
		font-size: 40px;
		margin-bottom: 8px;
		opacity: 0.8;
	}

	& > span {
		color: var(--body-1);
		font-size: 18px;
		opacity: 0.4;
	}
`;

export const DailyContainer = styled.div`
	width: 100%;

	& p.empty {
		font-size: 22px;
		font-style: italic;
		color: var(--body-2);
		margin-top: 24px;
	}

	& > h3 {
		margin-bottom: 12px;
	}

	& > ul {
		width: 100%;
		padding: 0 32px;

		& > li {
			color: var(--body-1);
			list-style: circle;
			margin-bottom: 24px;

			& button {
				margin: 0;
				padding: 0;
				color: var(--primary);
				cursor: pointer;
				font-weight: 500;
				padding: 1px 4px;
				border-radius: 4px;

				&:hover {
					color: var(--primary-hover);
				}
			}

			& strong.success {
				color: var(--success);
			}
		}
	}
`;

export const ObservationsModalContent = styled.div`
	width: 700px;
	max-width: 90vw;
	border-radius: var(--border-radius-1);
	background: var(--white);
	box-shadow: var(--modal-box-shadow);

	& > header {
		width: 100%;
		padding: 12px 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid var(--input-border-hover);

		& > button {
			cursor: pointer;
			width: 30px;
			height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: background-color 300ms;
			border-radius: var(--border-radius-1);
			background: none;

			&:hover {
				background: #dedede;
			}

			& > svg {
				color: var(--body-1);
				font-size: 18px;
			}
		}

		& > h3 {
			margin: 0;
			font-size: 18px;
			color: var(--heading-1);
		}
	}

	& > div.body {
		padding: 16px;
		max-height: 85vh;
		overflow-y: auto;

		& > p {
			color: var(--body-2);
			white-space: pre-line;
		}

		& > div {
			margin: 0;
		}

		& ul {
			border: none !important;
		}
	}

	& > div.buttons {
		border-top: 1px solid var(--input-border);
		grid-column: span 4;
		padding: 6px;
		margin-top: 4px;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		& > button ~ button {
			margin-left: 8px;
		}
	}
`;

export const Fallback = styled.div`
	font-size: 22px;
	color: var(--body-2);
`;

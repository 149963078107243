import React from 'react';
import { Notification as Toast, NotificationContent } from './styles';

import { toast } from 'react-toastify';

import { MdError, MdWarning } from 'react-icons/md';
import { FaCheckCircle } from 'react-icons/fa';
import { BsFillInfoCircleFill } from 'react-icons/bs';

const icons = {
	success: <FaCheckCircle />,
	error: <MdError />,
	warn: <MdWarning />,
	info: <BsFillInfoCircleFill />,
};

const Notification = (props) => <Toast {...props} position='bottom-left' />;

/**
 * Open the notification
 * @param {String} type
 * @param {String} message
 * @param {Object} options
 */
const notify = (type, message, options) => {
	return toast[type](
		<NotificationContent>
			{icons[type]}
			{message}
		</NotificationContent>,
		{ ...options }
	);
};

export default Notification;
export { notify };

import styled, { keyframes } from 'styled-components';

const animation = keyframes`
	0% {
		opacity: 1;
		transform: scale(.99);
	}

	50% {
		opacity: .6;
		transform: scale(1);
	}

	100% {
		opacity: 1;
		transform: scale(.99);
	}
`;

export const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	pointer-events: none;
	user-select: none;

	& svg {
		fill: var(--primary);
	}

	& > span {
		color: var(--primary);
		font-size: 28px;
		margin-top: 16px;
		font-weight: 600;
		animation: ${animation} 3s;
		animation-iteration-count: infinite;
	}
`;

import React from 'react';

import { AuthProvider } from './Auth';
// import { SocketProvider } from './Socket';
// import { UnreadMessagesProvider } from './UnreadMessages';

// const CombineProviders = ({ children }) => {
// 	return (
// 		<>
// 			<AuthProvider>
// 				<SocketProvider>
// 					<UnreadMessagesProvider>{children}</UnreadMessagesProvider>
// 				</SocketProvider>
// 			</AuthProvider>
// 		</>
// 	);
// };

const CombineProviders = ({ children }) => {
	return <AuthProvider>{children}</AuthProvider>;
};

export { CombineProviders };

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';

import { TiMicrophoneOutline } from 'react-icons/ti';
import { FaRegHandshake } from 'react-icons/fa';
import { AiOutlineHome, AiOutlineLogout, AiOutlineUser, AiOutlineClose } from 'react-icons/ai';
import { MobileMenu as Container, Link as LinkContent, ToggleButton, Version } from './styles';

const MobileMenu = ({ visible, closeMenu }) => {
	const { pathname } = useLocation();

	return (
		<OutsideClickHandler onOutsideClick={closeMenu}>
			<Container visible={visible}>
				<div className='toggle'>
					<ToggleButton onClick={closeMenu}>
						<AiOutlineClose />
					</ToggleButton>
				</div>
				<div className='menu'>
					<Link to='/home' onClick={closeMenu}>
						<LinkContent active={pathname.includes('home')}>
							<AiOutlineHome /> Início
						</LinkContent>
					</Link>
					<Link to='/send-offs' onClick={closeMenu}>
						<LinkContent active={pathname.includes('send-offs')}>
							<TiMicrophoneOutline /> Enviar Offs
						</LinkContent>
					</Link>
					<Link to='/contracts' onClick={closeMenu}>
						<LinkContent active={pathname.includes('contracts')}>
							<FaRegHandshake /> Meus Contratos
						</LinkContent>
					</Link>
					<Link to='/profile' onClick={closeMenu}>
						<LinkContent active={pathname.includes('profile')}>
							<AiOutlineUser /> Perfil
						</LinkContent>
					</Link>
				</div>

				<button className='exit'>
					<AiOutlineLogout /> Sair
				</button>

				<Version className='version'>
					<span>v.</span>
					{process.env.REACT_APP_VERSION}
				</Version>
			</Container>
		</OutsideClickHandler>
	);
};

export default MobileMenu;

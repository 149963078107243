import React, { useState, useCallback, useEffect, useRef, memo } from 'react';
import Loader from 'react-loader-spinner';
import Recdal from 'recdal';

import { Container as Table, Body, Row, Cell, Header, HeaderCell } from '../Table';
import OverflowMenu, { MenuItem } from '../OverflowMenu';
import {
	TabContent,
	BilletActions,
	BilletStatus,
	BilletsFallback,
	ModalContent,
	BilletDetailsAction,
	DateCell,
	TimelineIcon,
	Empty,
} from './styles';

import { RiMailSendLine, RiEyeLine } from 'react-icons/ri';
import { BiReceipt } from 'react-icons/bi';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { VscActivateBreakpoints } from 'react-icons/vsc';
import {
	MdHourglassEmpty,
	MdClose,
	MdMoneyOff,
	MdMonetizationOn,
	MdTimeline,
} from 'react-icons/md';

import { toCurrencyFormat } from '../../utils/helpers/strings';
import months from '../../constants/months';

const Billets = ({ data, contract, fetchingBillets }) => {
	const timelineModalRef = useRef(null);

	const [billetDetails, setBilletDetails] = useState({});
	const [billets, setBillets] = useState(data);

	const renderBilletStatusText = useCallback((status) => {
		switch (status) {
			case 'waiting':
				return 'Aguardando Pagamento';
			case 'unpaid':
				return 'Pagamento Não Efetuado';
			default:
				return 'Pagamento Confirmado';
		}
	}, []);

	const handleSeeDetails = useCallback((billet) => {
		setBilletDetails(billet);
		timelineModalRef.current.open();
	}, []);

	const renderIcon = useCallback((message) => {
		if (message.includes('Cobrança criada')) {
			return (
				<TimelineIcon color='primary'>
					<AiOutlinePlusCircle />
				</TimelineIcon>
			);
		} else if (message.includes('aguardando confirmação')) {
			return (
				<TimelineIcon color='primary'>
					<MdHourglassEmpty />
				</TimelineIcon>
			);
		} else if (message.includes('Cobrança enviada')) {
			return (
				<TimelineIcon color='primary'>
					<RiMailSendLine />
				</TimelineIcon>
			);
		} else if (message.includes('visualizou')) {
			return (
				<TimelineIcon color='primary'>
					<RiEyeLine />
				</TimelineIcon>
			);
		} else if (message.includes('não confirmado')) {
			return (
				<TimelineIcon color='danger'>
					<MdMoneyOff />
				</TimelineIcon>
			);
		} else if (message.includes('efetuado')) {
			return (
				<TimelineIcon color='success'>
					<MdMonetizationOn />
				</TimelineIcon>
			);
		} else {
			return (
				<TimelineIcon color='primary'>
					<VscActivateBreakpoints />
				</TimelineIcon>
			);
		}
	}, []);

	useEffect(() => {
		setBillets(data);
	}, [data]);

	if (contract.status === 0) {
		return (
			<TabContent>
				<Empty>
					<p>Contrato não está vigente</p>
				</Empty>
			</TabContent>
		);
	}

	if (fetchingBillets) {
		return (
			<TabContent>
				<BilletsFallback>
					<Loader type='TailSpin' height={35} width={35} color='#d8b135' />
					<span>buscando boletos</span>
				</BilletsFallback>
			</TabContent>
		);
	}

	return (
		<>
			<TabContent>
				{data.length ? (
					<Table>
						<Header>
							<Row>
								<HeaderCell>Valor</HeaderCell>
								<HeaderCell>Mês</HeaderCell>
								<HeaderCell>Emissão</HeaderCell>
								<HeaderCell>Vencimento</HeaderCell>
								<HeaderCell style={{ textAlign: 'center' }}>Status</HeaderCell>
								<HeaderCell>
									<BilletActions>Ações</BilletActions>
								</HeaderCell>
							</Row>
						</Header>
						<Body>
							{billets.map((billet) => (
								<Row key={billet._id}>
									<Cell>
										<strong>{toCurrencyFormat(billet.total / 100)}</strong>
									</Cell>
									<Cell>
										<span>
											{`${
												months.find(({ value }) => {
													return value === Number(billet.date.month);
												}).label
											} de ${billet.date.year}`}
										</span>
									</Cell>
									<Cell>
										<DateCell>
											{new Date(billet.created_at).toLocaleDateString()},
											<span>{new Date(billet.created_at).toLocaleTimeString()}</span>
										</DateCell>
									</Cell>
									<Cell>
										{billet.payment.banking_billet.expire_at.split('-').reverse().join('/')}
									</Cell>
									<Cell>
										<BilletStatus status={billet.status}>
											<div>{renderBilletStatusText(billet.status)}</div>
										</BilletStatus>
									</Cell>
									<Cell>
										<BilletActions>
											<OverflowMenu>
												<MenuItem icon={MdTimeline} onClick={() => handleSeeDetails(billet)}>
													Timeline
												</MenuItem>
												{billet.status === 'waiting' && (
													<MenuItem icon={RiMailSendLine}>Reenviar</MenuItem>
												)}
											</OverflowMenu>
										</BilletActions>
									</Cell>
								</Row>
							))}
						</Body>
					</Table>
				) : (
					<Empty>
						<BiReceipt />
						<p>Não há boletos</p>
					</Empty>
				)}
			</TabContent>

			<Recdal ref={timelineModalRef}>
				<ModalContent>
					<header>
						<h3>Histórico do boleto</h3>
						<button onClick={() => timelineModalRef.current.close()}>
							<MdClose />
						</button>
					</header>

					<div className='body'>
						{billetDetails?.history?.map((item, index) => (
							<BilletDetailsAction key={index}>
								<header>
									{renderIcon(item.message)}
									<span>{item.message}</span>
								</header>

								<p>
									{new Date(item.created_at).toLocaleDateString()},{' '}
									<span>{new Date(item.created_at).toLocaleTimeString()}</span>{' '}
								</p>

								<div className='line'></div>
							</BilletDetailsAction>
						))}
					</div>
				</ModalContent>
			</Recdal>
		</>
	);
};

export default memo(Billets);

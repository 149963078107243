import React from 'react';

import Button from '../../components/Button';
import { Container, Container404, Letter, Text } from './styles';

const NotFound = () => {
	return (
		<Container>
			<Container404>
				<Letter>4</Letter>
				<Letter>0</Letter>
				<Letter>4</Letter>
			</Container404>

			<Text>
				<strong>Ops, essa página não existe</strong>

				<span>Parece que nosso querido 404 está flutuando sem sentido</span>
			</Text>

			<Button className='get-back'>Bora voltar a página inicial?</Button>
		</Container>
	);
};

export default NotFound;

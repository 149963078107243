import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;

	& > div.header {
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;

		& > div.actions {
			display: flex;
			justify-content: flex-end;
			max-width: max-content;
			justify-content: flex-end;

			& > form {
				grid-template-columns: 1fr;

				& > .__input {
					width: 250px;
				}
			}

			& > form ~ form {
				margin-left: 8px;
			}
		}

		& > h1 {
			margin: 0;
		}
	}
`;

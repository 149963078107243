import './collapse.css';

import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import { Collapse } from 'react-collapse';

import { Container, Header, Loading, Script as Rows, Row, collapseTheme } from './styles';

import { useElement } from '../../hooks';

const Script = ({ script, loading, defaultOpen = false }) => {
	const { getElementColor, parseElementName } = useElement();

	const [showScript, setShowScript] = useState(defaultOpen);
	const [hightlight, setHightlight] = useState(false);

	if (loading) {
		return (
			<Container>
				<Loading>
					<Loader
						type='TailSpin'
						color='#d8b135'
						height={21}
						width={21}
						timeout={0}
						className='loader'
					/>
					<p>Buscando modelo...</p>
				</Loading>
			</Container>
		);
	}

	const handleToggleScriptCollapse = () => setShowScript(!showScript);

	const getVoicesQuantity = (script) => {
		const quantity = script.filter(({ type }) => type === 'OFF-MAIN').length;
		const content =
			quantity === 1 ? (
				<>
					Somente <strong>{quantity}</strong> arquivo deve ser enviado
				</>
			) : (
				<>
					<strong>{quantity}</strong> arquivos devem ser enviados
				</>
			);

		return content;
	};

	const onMouseOver = () =>
		setTimeout(() => {
			setHightlight(true);
		}, 500);

	const onMouseOut = () => setHightlight(false);

	return (
		<Container>
			{!defaultOpen && (
				<Header>
					<div>
						<h3>{script.name}</h3>
						<span>{getVoicesQuantity(script.body)}</span>
					</div>
					<button onClick={handleToggleScriptCollapse}>
						{showScript ? 'Esconder Modelo' : 'Visualizar Modelo'}
					</button>
				</Header>
			)}
			<Collapse isOpened={showScript} theme={collapseTheme}>
				<Rows onMouseOver={onMouseOver} onMouseOut={onMouseOut} hightlight={hightlight}>
					{script.body.map((element, index) => (
						<Row
							key={index}
							color={getElementColor({ type: element.type })}
							isNewBlock={element?.type === 'NEW-BLOCK'}
							className={element?.type === 'OFF-MAIN' && 'voice'}
						>
							{parseElementName({ type: element?.type })}
						</Row>
					))}
				</Rows>
			</Collapse>
		</Container>
	);
};

export default Script;

const miscBucket = process.env.REACT_APP_MISC_BUCKET;

const resolveFileSrc = ({ fileName, customS3Bucket, folder }) => {
	const bucketLink = customS3Bucket || miscBucket;
	const folderPath = (folder || []).join('/');
	const filename = fileName && fileName?.startsWith('/') ? fileName.slice(1) : fileName;
	const linksArr = [bucketLink, folderPath, filename].filter((v) => !!v);

	return linksArr.join('/');
};

export { resolveFileSrc };

import styled, { css } from 'styled-components';

export const Container = styled.div`
	grid-area: NAV;
	padding: 48px 0px 12px 0px;
	background: var(--dark);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;

	@media (max-width: 1024px) {
		padding: 0;

		& .version {
			display: none;
		}
	}
`;

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	& button.open-mobile-menu {
		display: none;
	}

	@media (max-width: 1024px) {
		height: 100%;
		flex-direction: row;
		padding: 0 16px;

		& button.open-mobile-menu {
			display: block;
		}
	}
`;

export const Menu = styled.nav`
	width: 100%;
	display: flex;
	flex-direction: column;

	& > a {
		margin-bottom: 12px;
		text-decoration: none;

		&:hover {
			text-decoration: none;
		}
	}

	@media (max-width: 1024px) {
		display: none;
	}
`;

export const Link = styled.div`
	width: 85%;
	display: flex;
	align-items: flex-end;
	padding: 12px 0 12px 16px;
	transition: background-color 100ms;
	cursor: pointer;
	border-radius: 0 var(--border-radius-3) var(--border-radius-3) 0;
	color: var(--menu-color);

	& > span {
		font-size: 15px;
		font-weight: 500;
		position: relative;
	}

	& > svg {
		font-size: 20px;
		margin-right: 12px;
	}

	&:hover {
		background: var(--menu-hover);
	}

	${({ active }) =>
		active &&
		css`
			background: var(--primary);
			color: var(--white);
			cursor: default;

			&:hover {
				background: var(--primary);
			}
		`}

	@media (max-width: 1024px) {
		& > span {
			display: none;
		}
	}
`;

export const Avatar = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	margin-bottom: 48px;
	padding: 0 32px;

	& > div.avatar-wrapper {
		width: 100px;
		height: 100px;

		& span {
			font-size: 28px !important;
		}

		& .__avatar {
			box-shadow: 2px 2px 18px 5px rgba(60, 60, 60, 0.1);
		}
	}

	& > div.infos {
		display: flex;
		flex-direction: column;
		margin-top: 12px;

		& > p {
			font-size: 15px;
			font-weight: 600;
			color: var(--white);
		}

		& > span {
			margin-top: 4px;
			font-size: 13px;
			color: var(--white);
			color: var(--body-2);
			font-weight: 600;
		}
	}

	@media (max-width: 1024px) {
		flex-direction: row-reverse;
		align-items: center;
		margin-bottom: 0;
		padding: 0;

		& > div.avatar-wrapper {
			width: 50px !important;
			height: 50px !important;

			& span {
				font-size: 18px !important;
			}
		}

		& > div.infos {
			height: 100%;
			margin-right: 16px;
			margin-top: 0;

			& > p {
				font-size: 13px;
			}

			& > span {
				font-size: 12px;
			}
		}
	}
`;

export const Exit = styled.button`
	background: none;
	width: 85%;
	display: flex;
	align-items: center;
	font-size: 15px;
	font-weight: 600;
	padding: 12px 0 12px 16px;
	transition: background-color 300ms;
	cursor: pointer;
	border-radius: 0 var(--border-radius-3) var(--border-radius-3) 0;
	color: var(--menu-color);
	justify-self: flex-end;

	& > svg {
		font-size: 20px;
		margin-right: 8px;
	}

	&:hover {
		background: var(--menu-hover);
	}

	${({ active }) =>
		active &&
		css`
			background: var(--menu-hover);
			color: var(--white);
		`}

	@media (max-width: 1024px) {
		display: none;
	}
`;

export const ToggleButton = styled.button`
	background: none;
	width: 42px;
	height: 42px;
	min-width: 42px;
	min-height: 42px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	transition: 150ms;
	cursor: pointer;

	& > svg {
		font-size: 24px;
		color: var(--menu-color);
	}

	&:hover {
		background: var(--menu-hover);
	}
`;

export const MobileMenu = styled.div`
	display: none;
	position: absolute;
	top: 0;
	left: ${({ visible }) => (visible ? 0 : '-200vw')};
	transition: left 150ms;
	height: 100vh;
	background: var(--dark);
	z-index: 100;
	padding: 32px 0 12px 0;

	& > div.menu {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;

		& > a {
			margin: 8px 0;
		}
	}

	& > div.toggle {
		margin-bottom: 24px;
		padding: 0 12px;
	}

	& > button.exit {
		background: none;
		width: 85%;
		display: flex;
		align-items: center;
		font-size: 15px;
		font-weight: 600;
		padding: 12px 0 12px 16px;
		transition: background-color 300ms;
		cursor: pointer;
		border-radius: 0 var(--border-radius-3) var(--border-radius-3) 0;
		color: var(--menu-color);
		justify-self: flex-end;

		& > svg {
			font-size: 20px;
			margin-right: 8px;
		}

		&:hover {
			background: var(--menu-hover);
		}
	}

	@media (max-width: 1024px) {
		width: 500px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	@media (max-width: 512px) {
		width: 100vw;
	}
`;

export const Overlay = styled.div`
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.5);
	transition: opacity 300ms;
	opacity: ${({ visible }) => (visible ? 1 : 0)};
	pointer-events: ${({ visible }) => (visible ? 'all' : 'none')};
	z-index: 50;
`;

export const Footer = styled.div`
	display: flex;
	align-items: flex-end;
	flex-direction: row-reverse;
	justify-content: space-between;
	padding: 0 16px;
`;

export const Copyright = styled.p`
	color: var(--body-2);
	font-size: 14px;
`;

export const Version = styled.p`
	color: var(--body-2);
	font-size: 13px;

	& > span {
		font-size: 12px;
	}
`;

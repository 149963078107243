import React, { useCallback, useRef } from 'react';
import Recdal from 'recdal';
import Loader from 'react-loader-spinner';

import { Heading3 } from '../../components/Typography';
import {
	ProgramDetails as Container,
	AbleToGeneratePlaylists,
	SeeObservations,
	NoObservations,
	ObservationsModalContent,
	DailyDataFallback,
	StoredOffs,
} from './styles';

import { MdClose } from 'react-icons/md';

const Infos = ({ contract, dailyData, offsNeeded, fallback }) => {
	const dailyInfosModalRef = useRef(null);
	const { observations, storedOffs } = dailyData || {};

	const handleOpenModal = useCallback(() => dailyInfosModalRef.current.open(), []);

	return (
		<>
			<Container>
				<Heading3>Informações</Heading3>
				{contract ? (
					<ul>
						<li>
							<span>Observações</span>

							{fallback === 'daily-data' ? (
								<DailyDataFallback>
									<Loader type='TailSpin' height={20} width={20} color='#d8b135' />
									<span>Buscando observações...</span>
								</DailyDataFallback>
							) : dailyData ? (
								observations ? (
									<SeeObservations onClick={handleOpenModal}>
										Clique para visualizar as observações
									</SeeObservations>
								) : (
									<NoObservations>Nenhuma observação para essa data</NoObservations>
								)
							) : (
								<NoObservations>Selecione programa e data</NoObservations>
							)}
						</li>
						<li>
							<span>Offs enviados</span>

							{fallback === 'daily-data' ? (
								<DailyDataFallback>
									<Loader type='TailSpin' height={20} width={20} color='#d8b135' />
									<span>Buscando offs...</span>
								</DailyDataFallback>
							) : dailyData ? (
								storedOffs !== 0 ? (
									storedOffs === offsNeeded ? (
										<StoredOffs>Todos os offs desse dia já foram enviados</StoredOffs>
									) : (
										<StoredOffs>{storedOffs} offs foram enviados nessa data</StoredOffs>
									)
								) : (
									<NoObservations>Nenhum off enviado nessa data</NoObservations>
								)
							) : (
								<NoObservations>Selecione programa e data</NoObservations>
							)}
						</li>
						<li>
							<span>Nome do Programa</span>
							<strong>{contract.program.name}</strong>
						</li>
						<li>
							<span>Descrição</span>
							<strong>{contract.program.description}</strong>
						</li>

						<li>
							<span>Contratante</span>
							<strong>
								{contract.radio.name} {contract.radio.surname}
							</strong>
						</li>

						<li>
							<span>É possível criar playlists</span>

							<AbleToGeneratePlaylists able={contract.playlistPrivilege}>
								{contract.playlistPrivilege ? 'Sim' : 'Não'}
							</AbleToGeneratePlaylists>
						</li>
					</ul>
				) : (
					<span className='empty'>Selecione o programa para ver suas informações</span>
				)}
			</Container>

			<Recdal ref={dailyInfosModalRef}>
				<ObservationsModalContent>
					<header>
						<h3>Observações para o dia {dailyData?.observations?.date}</h3>

						<button onClick={() => dailyInfosModalRef.current.close()}>
							<MdClose />
						</button>
					</header>
					<div className='body'>
						<p>{dailyData?.observations?.content}</p>
					</div>
				</ObservationsModalContent>
			</Recdal>
		</>
	);
};

export default Infos;

import React, { useState, useRef, useContext, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { POST } from '../../services/api';
import { AuthContext } from '../../store/Auth';

import Button from '../../components/Button';
import { Form, Input } from '../../components/Form';
import { Container, FormContainer, Header, Background, Overlay, Logo } from './styles';

import Bg1 from '../../assets/login/bg1.jpg';
import Bg2 from '../../assets/login/bg2.jpg';
import Bg3 from '../../assets/login/bg3.jpg';
import Bg4 from '../../assets/login/bg4.jpg';
import Bg5 from '../../assets/login/bg5.jpg';
import LogoSrc from '../../assets/login/logo.svg';

const Login = () => {
	const formRef = useRef(null);
	const { signIn } = useContext(AuthContext);
	const [authenticating, setAuthenticating] = useState(false);

	const getRandomLoginBackground = useCallback(() => {
		const backgrounds = [Bg1, Bg2, Bg3, Bg4, Bg5];
		const random = Math.floor(Math.random() * backgrounds.length) + 1;

		return backgrounds[random - 1];
	}, []);

	const memoizedBackground = useMemo(() => getRandomLoginBackground(), [getRandomLoginBackground]);

	const handleSignIn = useCallback(
		async (formData) => {
			try {
				const validationSchema = Yup.object().shape({
					email: Yup.string().required('Informe o seu email'),
					password: Yup.string().required('Informe a sua senha'),
				});

				await validationSchema.validate(formData, { abortEarly: false });

				setAuthenticating(true);
				formRef.current.setErrors({});

				const { data } = await POST('/api/v2/auth/caster', formData);

				setAuthenticating(false);

				signIn({
					user: data.caster,
					token: data.token,
					persistLogged: false,
					redirect: true,
				});
			} catch (error) {
				formRef.current.setErrors({});
				setAuthenticating(false);

				if (error instanceof Yup.ValidationError) {
					const errors = {};

					error.inner.forEach(({ path, message }) => (errors[path] = message));
					return formRef.current.setErrors(errors);
				} else {
					if (!error?.response) {
						return alert('Houve um erro no servidor, tente novamente');
					}

					const { status } = error?.response;

					switch (status) {
						case 404:
							return formRef.current.setFieldError('email', 'Usuário não encontrado');
						case 401:
							return formRef.current.setFieldError('email', 'Usuário ainda não foi aceito');
						case 400:
							return formRef.current.setFieldError('password', 'Senha incorreta');
						default:
							return alert('Houve um erro no servidor, tente novamente');
					}
				}
			}
		},
		[signIn]
	);

	return (
		<Container>
			<Header>
				<Logo>
					<img src={LogoSrc} alt='Logo | Módulo Locutor' />
				</Logo>

				<p>
					O Módulo Voz da Talk Radio reúne centenas de profissionais reconhecidos pela excelência na
					locução comercial, em uma única plataforma na internet. Locutores Profissionais, Locutores
					Publicitários, Locução Publicitária, Locutores de Publicidade, disponíveis para serem
					contratados de forma pontual, para realizar um determinado trabalho para a Talk Radio,
					para emissoras de rádio e agências. Cadastre-se e envie seu material de trabalho para
					aprovação.
				</p>
			</Header>

			<FormContainer>
				<h1>Entrar</h1>
				<Form ref={formRef} onSubmit={handleSignIn}>
					<Input size={4} name='email' type='email' label='Email' />
					<Input size={4} name='password' type='password' label='Senha' />

					<Button loading={authenticating} size={4}>
						Entrar
					</Button>
				</Form>
				<p>
					Ainda não possui uma conta?{' '}
					<Link to={{ pathname: '/register', state: { from: '/' } }}>Registre-se</Link>
				</p>
			</FormContainer>
			<Background bg={memoizedBackground}>
				<Overlay />
			</Background>
		</Container>
	);
};

export default Login;

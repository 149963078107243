import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { GET } from '../../services/api';
import { getQueryFromSearch } from '../../utils/helpers/strings';

import Fallback from '../../components/Fallback';
import AccordeonList from '../../components/AccordeonList';
import Breadcumbs from '../../components/Breadcumbs';
import { Heading1 } from '../../components/Typography';
import { Select, Form, Input } from '../../components/Form';
import { notify } from '../../components/Notification';
import { Container } from './styles';

import { AuthContext } from '../../store/Auth';

const breadcumbsStack = [
	{ label: 'Início', pathname: '/' },
	{ label: 'Contratos', pathname: null },
];

const statusOptions = [
	{ value: 'all', label: 'Exibir tudo' },
	{ value: 1, label: 'Somente vigentes' },
	{ value: 0, label: 'Somente pendentes' },
	{ value: 2, label: 'Somente encerrados' },
];

const Contracts = () => {
	const { user } = useContext(AuthContext);

	const searchRef = useRef(null);
	const history = useHistory();
	const { search, pathname } = useLocation();

	const [status, setStatus] = useState(null);
	const [loading, setLoading] = useState(true);
	const [filteredContracts, setFilteredContracts] = useState([]);
	const [contracts, setContracts] = useState([]);

	const handleFilter = useCallback(
		(e) => {
			const value = e.target.value.toUpperCase();
			const DEBOUNCE_TIMEOUT = 800;

			if (value.length <= 2) {
				return setFilteredContracts(contracts);
			}

			let timer = null;
			clearInterval(timer);

			timer = setTimeout(() => {
				const filtered = contracts.filter((item) => {
					const radioNameMatch = item.hirer?.radioName.toUpperCase().includes(value);
					const programNameMatch = item.program?.name.toUpperCase().includes(value);

					return radioNameMatch || programNameMatch;
				});

				return setFilteredContracts(filtered);
			}, DEBOUNCE_TIMEOUT);
		},
		[contracts]
	);

	const handleStatusChange = useCallback(
		(e) => {
			history.replace(`${pathname}?status=${e.value}`);
		},
		[history, pathname]
	);

	useEffect(() => {
		const query = getQueryFromSearch(search);

		if (query.hasOwnProperty('status')) {
			return setStatus(query.status);
		} else {
			return history.replace(`${pathname}?status=all`);
		}
	}, [search, history, pathname]);

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				setLoading(true);

				const _status = Number(status);
				let URI = `/api/v2/contracts?caster=${user._id}`;

				if (typeof _status === 'number' && _status >= 0 && _status <= 2) {
					URI = URI.concat(`&status=${status}`);
				}

				const {
					data: { contracts },
				} = await GET(URI);

				setContracts(contracts);
				setFilteredContracts(contracts);
				setLoading(false);
			} catch (error) {
				console.error(error);
				notify('error', 'Houve um erro, tente novamente');
			}
		};

		if (status !== null) {
			fetchInitialData();
		}
	}, [status, user]);

	if (loading) {
		return <Fallback />;
	}

	return (
		<Container>
			<Breadcumbs stack={breadcumbsStack} />

			<div className='header'>
				<Heading1>Contratos</Heading1>

				<div className='actions'>
					<Form ref={searchRef}>
						<Input onKeyUp={handleFilter} name='search' placeholder='Pesquisar' />
					</Form>

					<Form>
						<Select
							name='status'
							onChange={handleStatusChange}
							options={statusOptions}
							value={statusOptions.find((item) => String(item.value) === String(status))}
						/>
					</Form>
				</div>
			</div>

			<AccordeonList data={filteredContracts} />
		</Container>
	);
};

export default Contracts;

import styled, { css } from 'styled-components';

export const Container = styled.div`
	width: 40px;
	height: 40px;
	position: relative;
`;

export const Button = styled.button`
	width: 100%;
	height: 100%;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: none;
	transition: background-color 200ms;
	cursor: pointer;

	&:hover {
		background: #efefef;
	}

	& > svg {
		color: var(--body-2);
		font-size: 24px;
	}
`;

export const MenuContainer = styled.ul`
	position: absolute;
	width: max-content;
	z-index: 100000000;
	background: var(--white);
	border-radius: var(--border-radius-1);
	top: ${({ top }) => top};
	left: ${({ left }) => left};
	overflow: hidden;
	padding: 8px 0;
	box-shadow:
		rgba(0, 0, 0, 0.07) 0px 1px 2px,
		rgba(0, 0, 0, 0.07) 0px 2px 4px,
		rgba(0, 0, 0, 0.07) 0px 4px 8px,
		rgba(0, 0, 0, 0.07) 0px 8px 16px,
		rgba(0, 0, 0, 0.07) 0px 16px 32px,
		rgba(0, 0, 0, 0.07) 0px 32px 64px;
`;

export const MenuItem = styled.li`
	width: max-content;
	width: 100%;
	max-width: 500px;
	padding: 8px 12px;
	font-size: 15px;
	color: var(--body-2);
	transition: background-color 200ms;
	cursor: pointer;
	min-width: 140px;
	display: flex;
	align-items: center;
	font-weight: 500;

	& > svg {
		margin-right: 8px;
		font-size: 20px;
		color: var(--body-2);
	}

	&:hover {
		background: #efefef;
	}

	${({ isDanger }) =>
		isDanger &&
		css`
			color: var(--danger);

			& > svg {
				color: var(--danger);
			}

			&:hover {
				background: #ffd8d8;
			}
		`}

	${({ disabled }) =>
		disabled &&
		css`
			opacity: 0.6;
			pointer-events: none !important;

			& * {
				pointer-events: none !important;
			}
		`}
`;

export const Divider = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin: 6px 0;

	& > span {
		width: 90%;
		height: 1px;
		background: ${({ isDanger }) => (isDanger ? 'var(--danger)' : '#dedede')};
	}
`;
